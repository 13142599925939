<template>
  <div class="my-second jiang bgcolor">
      <Head :title="$t('wallet.view_info')" :show="true" ></Head>
      <div class="common-box jiang-box">
          <div class="info-box">
                <van-cell-group v-if="info.is_sell_winning">
                    <van-cell :title="$t('add.re_time')" :value="info.sell_time" />
                    <van-cell :title="$t('add.re_amount')" :value="info.amount + ' '+ info.current_unit "  />
                    <van-cell :title="$t('add.re_status')" :value="info.sell_status"  />
                </van-cell-group>
                <van-cell-group v-if="info.is_mail_winning" >
                    <van-cell class="address-info" :title="$t('add.ship_no')" :value="info.order_sn" />
                    <van-cell :title="$t('add.ship_time')" :value="info.mail_time" />
                    <van-cell :title="$t('add.email_status')" :value="$t('add.is_ship_'+info.is_ship)"  />
                    <van-cell class="address-info" :title="$t('add.email_address')" :value="info.add_info.address"  />
                </van-cell-group>
          </div>
      </div>
  </div>
</template>

<script>
import {randomString} from '@/common'
export default {
    mounted(){
        this.$ajax.winnerOrderDetail({
            winning_orderid:this.$route.query.win_orderid,
            form_token:randomString()
        }).then(res=>{
            if(res.code==200){
                this.info = {
                    ...res.data,
                    amount:parseFloat(res.data.amount).toLocaleString('en', {minimumFractionDigits: 2,maximumFractionDigits:2})
                }
            }
        })
    },
    data(){
        return {
            info:{}
        }
    }
}
</script>
